.navigationList {
  display: flex;
  list-style-type: none;
  justify-content: center;
  margin: 0;
  padding: 1rem 0;
  border-bottom: 1px solid var(--gray-border);
}

.navigationItem {
  margin: 0 1rem;
  font-size: 1.25rem;
  text-transform: uppercase;
  font-weight: 600;

  &:first-of-type {
    margin-left: 0;
  }
  &:last-of-type {
    margin-right: 0;
  }
}

.label {
  display: none;
  white-space: nowrap;
  @media screen and (min-width: 45em) {
    display: initial;
  }
}

.icon {
  margin-right: 0.3rem;
  line-height: 1.15;
  vertical-align: bottom;
}

.link {
  display: flex;
  align-items: center;
}
